import Vue from "vue";
import Vuetify, {
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAvatar,
  VBtn,
  VCard,
  VCol,
  VContainer,
  VDataTable,
  VDataTableHeader,
  VDialog,
  VFlex,
  VIcon,
  VImg,
  VLayout,
  VList,
  VListGroup,
  VListItem,
  VMenu,
  VMain,
  VNavigationDrawer,
  VProgressCircular,
  VRow,
  VSheet,
  VSpeedDial,
  VSpacer,
  VToolbar,
  VTooltip
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAvatar,
    VBtn,
    VCard,
    VCol,
    VContainer,
    VDataTable,
    VDataTableHeader,
    VDialog,
    VFlex,
    VIcon,
    VImg,
    VLayout,
    VList,
    VListGroup,
    VListItem,
    VMenu,
    VMain,
    VNavigationDrawer,
    VProgressCircular,
    VRow,
    VSheet,
    VSpeedDial,
    VSpacer,
    VToolbar,
    VTooltip
  },
  directives: {
    Ripple
  }
});

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#3434BF",
        secondary: "#ffffff",
        disabled: "#f2f2f2",
        body: "#e9ebee",
        hover: "#EAEEFB",
        success: "#37B070",
        warning: "#F6CB5C",
        purple: "#762582",
        error: "#E14646",
        black: "#000000",
        black50: "#808080",
        black60: "#666666",
        black10: "#E5E5E5",
        UNIBH: "#00345C",
        USJT: "#353E88",
        UNA: "#E40521",
        SOCIESC: "#283479",
        UNISUL: "#283479",
        AGES: "#283479",
        UNICURITIBA: "#00345C",
        FASEH: "#002D74",
        UNIFG: "#00529C",
        FPB: "#0A548B",
        UNIFGUA: "#00428B",
        UAM: "#007A6A",
        FADERGS: "#BC2223",
        IBMR: "#157945",
        MCAMPOS: "#93A396",
        UNIFACS: "#193A74",
        UNIRITTER: "#E74B05",
        UNP: "#E45A00"
      },
      dark: {
        primary: "#3434BF",
        secondary: "#1a1a1a",
        disabled: "#333333",
        black60: "#666666",
        UNIBH: "#262626",
        USJT: "#262626",
        UNA: "#262626",
        SOCIESC: "#262626",
        UNISUL: "#262626",
        AGES: "#262626",
        UNICURITIBA: "#262626",
        FASEH: "#262626",
        UNIFG: "#262626",
        FPB: "#262626",
        UNIFGUA: "#262626",
        UAM: "#262626",
        FADERGS: "#262626",
        IBMR: "#262626",
        MCAMPOS: "#262626",
        UNIFACS: "#262626",
        UNIRITTER: "#262626",
        UNP: "#262626"
      }
    }
  }
};

export default new Vuetify(opts);
