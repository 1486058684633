import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import util from "./modules/util";
import aluno from "./modules/aluno";

export default new Vuex.Store({
  modules: {
    util,
    aluno
  }
});
