const AUMENTAR_FONT_SIZE = "AUMENTAR_FONT_SIZE";
const DIMINUIR_FONT_SIZE = "DIMINUIR_FONT_SIZE";
const PADRAO_FONT_SIZE = "PADRAO_FONT_SIZE";
const ALTERAR_DRAWER = "ALTERAR_DRAWER";
const ALTERAR_IE = "ALTERAR_IE";
const ALTERAR_TOKEN = "ALTERAR_TOKEN";
const ALTERAR_TOKEN_IDENTITY = "ALTERAR_TOKEN_IDENTITY";
const ALTERAR_LINK = "ALTERAR_LINK";
const state = {
  font: 1,
  token: "",
  ie: "",
  drawer: false,
  link: "",
  tokenIdentity: null
};

const mutations = {
  [AUMENTAR_FONT_SIZE](state) {
    if (state.font <= 1.75) {
      state.font += 0.25;
    }
  },
  [DIMINUIR_FONT_SIZE](state) {
    if (state.font > 1) {
      state.font -= 0.25;
    }
  },
  [PADRAO_FONT_SIZE](state) {
    state.font = 1;
  },
  [ALTERAR_DRAWER](state) {
    state.drawer = !state.drawer;
  },
  [ALTERAR_TOKEN](state, payload) {
    state.token = payload;
  },
  [ALTERAR_TOKEN_IDENTITY](state, payload) {
    state.tokenIdentity = payload;
  },
  [ALTERAR_IE](state, payload) {
    state.ie = payload;
  },
  [ALTERAR_LINK](state, payload) {
    state.link = payload;
  }
};

const actions = {
  aumentarFont(context) {
    context.commit("AUMENTAR_FONT_SIZE");
  },
  fontPadrao(context) {
    context.commit("PADRAO_FONT_SIZE");
  },
  diminuirFont(context) {
    context.commit("DIMINUIR_FONT_SIZE");
  },
  alterarDrawer(context) {
    context.commit("ALTERAR_DRAWER");
  },
  alterarIe(context, payload) {
    context.commit("ALTERAR_IE", payload);
  },
  alterarToken(context, payload) {
    context.commit("ALTERAR_TOKEN", payload);
  },
  alterarTokenIdentity(context, payload) {
    context.commit("ALTERAR_TOKEN_IDENTITY", payload);
  },
  alterarLink(context, payload) {
    context.commit("ALTERAR_LINK", payload);
  }
};

export default { state, mutations, actions };
