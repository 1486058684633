<template>
  <v-app v-bind:style="{ fontSize: fontSize + 'em' }">
    <v-main class="body">
      <v-main class="pa-0">
        <router-view></router-view>
      </v-main>
    </v-main>
  </v-app>
</template>

<script>
//import Footer from "./components/Footer";
//import Vlibras from "@/components/Vlibras.vue";
export default {
  name: "App",
  computed: {
    fontSize() {
      return this.$store.state.util.font;
    }
  }
};
</script>
