const ALTERAR_ALUNO = "ALTERAR_ALUNO";

const state = {
  aluno: {}
};

const mutations = {
  [ALTERAR_ALUNO](state, value) {
    state.aluno = value;
  }
};

const actions = {
  alterarAluno(context, value) {
    context.commit("ALTERAR_ALUNO", value);
  }
};

export default { state, mutations, actions };
