import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTagManager from "vue-tag-manager";

import "@/assets/sass/index.sass";

Vue.config.productionTip = false;

// Tagmanager
let envTagManager = process.env.VUE_APP_URL_TAG_MANAGER;
Vue.use(VueTagManager, {
  gtmId: envTagManager
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
